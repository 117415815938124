

import moment from 'moment'
export default {
  name: 'SlotCard',
  components: {
  },
  props: {
    slotsData: Object,
    slotsNewData: Object,
    deleteAppointment: {
      type: Function,
      default: () => {
        return false
      }
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD-MM-YYYY')
    }
  }
}
