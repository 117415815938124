import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f3f5a6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slot-card__group-title" }
const _hoisted_2 = { class: "slot-card__value" }
const _hoisted_3 = { class: "slot-card__group-title" }
const _hoisted_4 = { class: "slot-card__available-days" }
const _hoisted_5 = { class: "slot-card__value" }
const _hoisted_6 = { class: "slot-card__title" }
const _hoisted_7 = { class: "slot-card__bottom-group" }
const _hoisted_8 = { class: "slot-card__group-title" }
const _hoisted_9 = { class: "slot-card__value" }
const _hoisted_10 = { class: "slot-card__group-title" }
const _hoisted_11 = { class: "slot-card__value" }
const _hoisted_12 = { class: "slot-card__group-title" }
const _hoisted_13 = { class: "slot-card__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.slotsNewData, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "slot-card",
      key: item.id
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('message.property.first_day_hint')), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString($options.formatDate($props.slotsData?.timeslot?.date_starts_from)), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('message.property.available_days')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(((item.days).join(", "))), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('message.property.beginning_time')), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(item.start), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('message.property.duration')), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(item.visit_duration), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('message.property.end_time')), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(item.end), 1)
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}